import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
//import Img from 'gatsby-image'
import { getImage, GatsbyImage  } from "gatsby-plugin-image"

const Image = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                gatsbyImageData(
                  width:64
                  height:64
                  layout: FIXED
                )
              }
            }
          }
        }
      }
    `}

    render={(data) => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename);
      });
      if (!image) { return null; }

//      const imageSizes = image.node.childImageSharp.fixed;
      return (
        <GatsbyImage
          image={getImage(image.node.childImageSharp)}
          alt={props.alt}
//          fixed={imageSizes}
//          fadeIn={false}
          loading={"lazy"}
        />
      );
    }}
  />
)
export default Image